.backdrop {
  opacity: .85;
  pointer-events: all;
  background: #000;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateZ(0);
}

.layer {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.focusLock {
  flex-direction: column;
  display: flex;
}

@media screen and (max-width: 800px) {
  .focusLock {
    width: 100%;
    height: 100%;
  }
}

.root {
  background-color: var(--background-secondary);
  border: 1px solid var(--background-header-secondary);
  pointer-events: all;
  border-radius: 8px;
  flex-direction: column;
  max-height: 85vh;
  margin: 0 auto;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 0 1px #2f313714, 0 16px 40px #00000029;
}

@media screen and (max-width: 800px) {
  .root {
    border-radius: 0;
    width: 100%;
    height: 100%;
    max-height: 100%;
  }
}

.root.default {
  width: 600px;
  min-height: 400px;
}

.root.small {
  width: 440px;
  min-height: 200px;
}

.root.large {
  width: 800px;
  height: 860px;
}

.root.xlarge {
  width: 90%;
  height: 100%;
}

.layout {
  z-index: 1;
  flex: none;
  padding: 16px;
  position: relative;
  overflow-x: hidden;
}

.layout.header {
  flex-direction: column;
  gap: 14px;
  display: flex;
}

.layout.header.dark {
  background-color: var(--background-secondary);
}

.layout.footer {
  background-color: var(--background-secondary);
  box-shadow: 0 -1px 0 var(--background-header-secondary);
  flex-flow: row-reverse;
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
}

.headerInner {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.headerText h3 {
  color: var(--text-primary);
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
}

.headerInner button {
  color: var(--text-primary-muted);
  cursor: pointer;
  opacity: .5;
  background: none;
  justify-content: center;
  align-items: center;
  transition: opacity .2s ease-in-out;
  display: flex;
}

.headerInner button:hover {
  color: var(--text-primary);
  opacity: 1;
}

.content {
  flex: auto;
  height: 100%;
  padding: 0 16px 16px;
}
