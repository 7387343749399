*, :before, :after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  -moz-tab-size: 4;
  tab-size: 4;
  line-height: 1.5;
  font-family: var(--font-sans);
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: var(--font-mono);
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

input:where([type="button"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

input:where([type="reset"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden]:where(:not([hidden="until-found"])) {
  display: none;
}

.\!container {
  width: 100% !important;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .\!container {
    max-width: 640px !important;
  }

  .container {
    max-width: 640px;
  }
}

@media (min-width: 841px) {
  .\!container {
    max-width: 841px !important;
  }

  .container {
    max-width: 841px;
  }
}

@media (min-width: 1024px) {
  .\!container {
    max-width: 1024px !important;
  }

  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .\!container {
    max-width: 1280px !important;
  }

  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .\!container {
    max-width: 1536px !important;
  }

  .container {
    max-width: 1536px;
  }
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.-left-1 {
  left: -.25rem;
}

.-top-\[14px\] {
  top: -14px;
}

.-top-\[68px\] {
  top: -68px;
}

.bottom-0 {
  bottom: 0;
}

.bottom-\[calc\(100\%\+8px\)\] {
  bottom: calc(100% + 8px);
}

.bottom-px {
  bottom: 1px;
}

.left-0 {
  left: 0;
}

.left-1\/2 {
  left: 50%;
}

.left-2 {
  left: .5rem;
}

.left-4 {
  left: 1rem;
}

.left-\[10px\] {
  left: 10px;
}

.left-\[14px\] {
  left: 14px;
}

.left-\[2px\] {
  left: 2px;
}

.left-auto {
  left: auto;
}

.right-0 {
  right: 0;
}

.right-2 {
  right: .5rem;
}

.right-4 {
  right: 1rem;
}

.right-auto {
  right: auto;
}

.right-full {
  right: 100%;
}

.top-0 {
  top: 0;
}

.top-1\/2 {
  top: 50%;
}

.top-2 {
  top: .5rem;
}

.top-6 {
  top: 1.5rem;
}

.top-\[\.1rem\] {
  top: .1rem;
}

.top-\[2px\] {
  top: 2px;
}

.top-\[61px\] {
  top: 61px;
}

.top-\[68px\] {
  top: 68px;
}

.top-full {
  top: 100%;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-\[1\] {
  z-index: 1;
}

.z-\[4999\] {
  z-index: 4999;
}

.z-elevated-1 {
  z-index: var(--z-index-elevated-1);
}

.z-elevated-3 {
  z-index: var(--z-index-elevated-3);
}

.z-overlay {
  z-index: var(--z-index-overlay);
}

.m-0 {
  margin: 0;
}

.m-4 {
  margin: 1rem;
}

.-mx-0\.5 {
  margin-left: -.125rem;
  margin-right: -.125rem;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-\[16px\] {
  margin-left: 16px;
  margin-right: 16px;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-px {
  margin-top: 1px;
  margin-bottom: 1px;
}

.-ml-1 {
  margin-left: -.25rem;
}

.-ml-\[120px\] {
  margin-left: -120px;
}

.-ml-\[16px\] {
  margin-left: -16px;
}

.-ml-\[2px\] {
  margin-left: -2px;
}

.-ml-\[48px\] {
  margin-left: -48px;
}

.-ml-\[72px\] {
  margin-left: -72px;
}

.-mt-2 {
  margin-top: -.5rem;
}

.-mt-\[\.2em\] {
  margin-top: -.2em;
}

.-mt-\[\.3em\] {
  margin-top: -.3em;
}

.-mt-\[3px\] {
  margin-top: -3px;
}

.-mt-px {
  margin-top: -1px;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-\[\.5rem\] {
  margin-bottom: .5rem;
}

.mb-\[16px\] {
  margin-bottom: 16px;
}

.mb-\[2px\] {
  margin-bottom: 2px;
}

.mb-\[4px\] {
  margin-bottom: 4px;
}

.ml-0 {
  margin-left: 0;
}

.ml-0\.5 {
  margin-left: .125rem;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-\[\.25rem\] {
  margin-left: .25rem;
}

.ml-\[1rem\] {
  margin-left: 1rem;
}

.ml-\[4px\] {
  margin-left: 4px;
}

.ml-auto {
  margin-left: auto;
}

.ml-px {
  margin-left: 1px;
}

.mr-0\.5 {
  margin-right: .125rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-\[\.25rem\] {
  margin-right: .25rem;
}

.mr-\[\.875rem\] {
  margin-right: .875rem;
}

.mr-\[8px\] {
  margin-right: 8px;
}

.mr-px {
  margin-right: 1px;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-\[\.125rem\] {
  margin-top: .125rem;
}

.mt-\[\.1em\] {
  margin-top: .1em;
}

.mt-\[\.2em\] {
  margin-top: .2em;
}

.mt-\[\.3em\] {
  margin-top: .3em;
}

.mt-\[\.4em\] {
  margin-top: .4em;
}

.mt-\[0\.2em\] {
  margin-top: .2em;
}

.mt-\[1\.0625rem\] {
  margin-top: 1.0625rem;
}

.mt-\[1\.5rem\] {
  margin-top: 1.5rem;
}

.mt-\[20px\] {
  margin-top: 20px;
}

.mt-\[30px\] {
  margin-top: 30px;
}

.mt-\[3px\] {
  margin-top: 3px;
}

.mt-\[4px\] {
  margin-top: 4px;
}

.mt-auto {
  margin-top: auto;
}

.mt-px {
  margin-top: 1px;
}

.box-border {
  box-sizing: border-box;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.list-item {
  display: list-item;
}

.hidden {
  display: none;
}

.h-0 {
  height: 0;
}

.h-0\.5 {
  height: .125rem;
}

.h-1 {
  height: .25rem;
}

.h-1\.5 {
  height: .375rem;
}

.h-10 {
  height: 2.5rem;
}

.h-12 {
  height: 3rem;
}

.h-14 {
  height: 3.5rem;
}

.h-16 {
  height: 4rem;
}

.h-2 {
  height: .5rem;
}

.h-2\.5 {
  height: .625rem;
}

.h-20 {
  height: 5rem;
}

.h-24 {
  height: 6rem;
}

.h-3 {
  height: .75rem;
}

.h-3\.5 {
  height: .875rem;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-\[\.9375rem\] {
  height: .9375rem;
}

.h-\[1\.375rem\] {
  height: 1.375rem;
}

.h-\[100px\] {
  height: 100px;
}

.h-\[100svh\] {
  height: 100svh;
}

.h-\[105px\] {
  height: 105px;
}

.h-\[10px\] {
  height: 10px;
}

.h-\[12px\] {
  height: 12px;
}

.h-\[140px\] {
  height: 140px;
}

.h-\[14px\] {
  height: 14px;
}

.h-\[18px\] {
  height: 18px;
}

.h-\[1px\] {
  height: 1px;
}

.h-\[20px\] {
  height: 20px;
}

.h-\[210px\] {
  height: 210px;
}

.h-\[280px\] {
  height: 280px;
}

.h-\[36px\] {
  height: 36px;
}

.h-\[38px\] {
  height: 38px;
}

.h-\[40px\] {
  height: 40px;
}

.h-\[42px\] {
  height: 42px;
}

.h-\[440px\] {
  height: 440px;
}

.h-\[44px\] {
  height: 44px;
}

.h-\[498px\] {
  height: 498px;
}

.h-\[7\.2px\] {
  height: 7.2px;
}

.h-\[780px\] {
  height: 780px;
}

.h-\[880px\] {
  height: 880px;
}

.h-\[calc\(100\%-64px\)\] {
  height: calc(100% - 64px);
}

.h-full {
  height: 100%;
}

.h-px {
  height: 1px;
}

.h-screen {
  height: 100vh;
}

.max-h-\[1\.25em\] {
  max-height: 1.25em;
}

.max-h-\[490px\] {
  max-height: 490px;
}

.max-h-\[50vh\] {
  max-height: 50vh;
}

.max-h-\[88px\] {
  max-height: 88px;
}

.max-h-\[957px\] {
  max-height: 957px;
}

.max-h-full {
  max-height: 100%;
}

.min-h-0 {
  min-height: 0;
}

.min-h-\[1\.375rem\] {
  min-height: 1.375rem;
}

.min-h-\[16px\] {
  min-height: 16px;
}

.min-h-\[200px\] {
  min-height: 200px;
}

.min-h-\[24px\] {
  min-height: 24px;
}

.min-h-\[38px\] {
  min-height: 38px;
}

.min-h-\[400px\] {
  min-height: 400px;
}

.min-h-\[44px\] {
  min-height: 44px;
}

.min-h-\[auto\] {
  min-height: auto;
}

.min-h-full {
  min-height: 100%;
}

.w-1 {
  width: .25rem;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/4 {
  width: 25%;
}

.w-10 {
  width: 2.5rem;
}

.w-11 {
  width: 2.75rem;
}

.w-12 {
  width: 3rem;
}

.w-14 {
  width: 3.5rem;
}

.w-16 {
  width: 4rem;
}

.w-2 {
  width: .5rem;
}

.w-2\.5 {
  width: .625rem;
}

.w-20 {
  width: 5rem;
}

.w-24 {
  width: 6rem;
}

.w-3 {
  width: .75rem;
}

.w-3\.5 {
  width: .875rem;
}

.w-3\/4 {
  width: 75%;
}

.w-4 {
  width: 1rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-7 {
  width: 1.75rem;
}

.w-8 {
  width: 2rem;
}

.w-\[10\.8px\] {
  width: 10.8px;
}

.w-\[100vw\] {
  width: 100vw;
}

.w-\[10px\] {
  width: 10px;
}

.w-\[12px\] {
  width: 12px;
}

.w-\[14px\] {
  width: 14px;
}

.w-\[18px\] {
  width: 18px;
}

.w-\[2\.5rem\] {
  width: 2.5rem;
}

.w-\[20px\] {
  width: 20px;
}

.w-\[230px\] {
  width: 230px;
}

.w-\[250px\] {
  width: 250px;
}

.w-\[300px\] {
  width: 300px;
}

.w-\[410px\] {
  width: 410px;
}

.w-\[480px\] {
  width: 480px;
}

.w-\[498px\] {
  width: 498px;
}

.w-\[500px\] {
  width: 500px;
}

.w-\[512px\] {
  width: 512px;
}

.w-\[56px\] {
  width: 56px;
}

.w-\[600px\] {
  width: 600px;
}

.w-\[90px\] {
  width: 90px;
}

.w-auto {
  width: auto;
}

.w-fit {
  width: -moz-fit-content;
  width: fit-content;
}

.w-full {
  width: 100%;
}

.w-px {
  width: 1px;
}

.min-w-0 {
  min-width: 0;
}

.min-w-4 {
  min-width: 1rem;
}

.min-w-\[10ch\] {
  min-width: 10ch;
}

.min-w-\[160px\] {
  min-width: 160px;
}

.min-w-\[2rem\] {
  min-width: 2rem;
}

.min-w-\[auto\] {
  min-width: auto;
}

.min-w-full {
  min-width: 100%;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-52 {
  max-width: 13rem;
}

.max-w-\[200px\] {
  max-width: 200px;
}

.max-w-\[268px\] {
  max-width: 268px;
}

.max-w-\[432px\] {
  max-width: 432px;
}

.max-w-\[90vw\] {
  max-width: 90vw;
}

.max-w-full {
  max-width: 100%;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-sm {
  max-width: 24rem;
}

.flex-1 {
  flex: 1;
}

.flex-\[1000_0_auto\] {
  flex: 1000 0 auto;
}

.flex-auto {
  flex: auto;
}

.flex-initial {
  flex: 0 auto;
}

.flex-none {
  flex: none;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.flex-grow-0 {
  flex-grow: 0;
}

.basis-\[10ch\] {
  flex-basis: 10ch;
}

.border-collapse {
  border-collapse: collapse;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-full {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform-gpu {
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes ping {
  75%, 100% {
    opacity: 0;
    transform: scale(2);
  }
}

.animate-ping {
  animation: 1s cubic-bezier(0, 0, .2, 1) infinite ping;
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.cursor-default {
  cursor: default;
}

.cursor-grab {
  cursor: grab;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.touch-none {
  touch-action: none;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.select-text {
  -webkit-user-select: text;
  user-select: text;
}

.resize-none {
  resize: none;
}

.resize {
  resize: both;
}

.appearance-none {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-\[1fr\,3fr\] {
  grid-template-columns: 1fr 3fr;
}

.grid-cols-\[1fr\,auto\] {
  grid-template-columns: 1fr auto;
}

.grid-cols-\[4\.5rem\,1fr\] {
  grid-template-columns: 4.5rem 1fr;
}

.grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr));
}

.grid-rows-\[1fr\,auto\], .grid-rows-\[1fr_auto\] {
  grid-template-rows: 1fr auto;
}

.grid-rows-\[24px_auto\] {
  grid-template-rows: 24px auto;
}

.grid-rows-\[3\.5rem\,1fr\] {
  grid-template-rows: 3.5rem 1fr;
}

.grid-rows-\[auto\,1fr\] {
  grid-template-rows: auto 1fr;
}

.grid-rows-\[auto\] {
  grid-template-rows: auto;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.items-stretch {
  align-items: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-0\.5 {
  gap: .125rem;
}

.gap-1 {
  gap: .25rem;
}

.gap-1\.5 {
  gap: .375rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-2\.5 {
  gap: .625rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-\[12px\] {
  gap: 12px;
}

.gap-\[4px\] {
  gap: 4px;
}

.gap-\[8px\] {
  gap: 8px;
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.25rem * var(--tw-space-x-reverse));
  margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.self-center {
  align-self: center;
}

.self-stretch {
  align-self: stretch;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.overflow-ellipsis, .text-ellipsis {
  text-overflow: ellipsis;
}

.whitespace-normal {
  white-space: normal;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre {
  white-space: pre;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.whitespace-break-spaces {
  white-space: break-spaces;
}

.break-words {
  overflow-wrap: break-word;
}

.break-all {
  word-break: break-all;
}

.rounded {
  border-radius: .25rem;
}

.rounded-\[1px\] {
  border-radius: 1px;
}

.rounded-\[4px\] {
  border-radius: 4px;
}

.rounded-\[8px\] {
  border-radius: 8px;
}

.rounded-\[inherit\] {
  border-radius: inherit;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-none {
  border-radius: 0;
}

.rounded-sm {
  border-radius: .125rem;
}

.rounded-b-md {
  border-bottom-right-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

.rounded-r-full {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.rounded-t-lg {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.rounded-bl-lg {
  border-bottom-left-radius: .5rem;
}

.rounded-br-lg {
  border-bottom-right-radius: .5rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-4 {
  border-width: 4px;
}

.border-\[6px\] {
  border-width: 6px;
}

.border-\[8px\] {
  border-width: 8px;
}

.border-x-theme-border-width {
  border-left-width: var(--theme-border-width);
  border-right-width: var(--theme-border-width);
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-b-theme-border-width {
  border-bottom-width: var(--theme-border-width);
}

.border-l {
  border-left-width: 1px;
}

.border-r {
  border-right-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-t-\[2px\] {
  border-top-width: 2px;
}

.border-t-theme-border-width {
  border-top-width: var(--theme-border-width);
}

.border-solid {
  border-style: solid;
}

.border-dashed {
  border-style: dashed;
}

.border-none {
  border-style: none;
}

.border-background-header-secondary {
  border-color: var(--background-header-secondary);
}

.border-background-modifier-hover {
  border-color: var(--background-modifier-hover);
}

.border-background-primary {
  border-color: var(--background-primary);
}

.border-brand-primary {
  border-color: var(--brand-primary);
}

.border-gray-700 {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity, 1));
}

.border-status-danger {
  border-color: var(--status-danger);
}

.border-text-primary-muted {
  border-color: var(--text-primary-muted);
}

.border-text-tertiary {
  border-color: var(--text-tertiary);
}

.border-text-tertiary-muted {
  border-color: var(--text-tertiary-muted);
}

.border-theme-border {
  border-color: var(--theme-border);
}

.border-transparent {
  border-color: #0000;
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity, 1));
}

.border-l-transparent {
  border-left-color: #0000;
}

.border-r-transparent {
  border-right-color: #0000;
}

.border-t-transparent {
  border-top-color: #0000;
}

.bg-background-channel-header {
  background-color: var(--background-channel-header);
}

.bg-background-chat-primary {
  background-color: var(--background-chat-primary);
}

.bg-background-header-primary {
  background-color: var(--background-header-primary);
}

.bg-background-header-secondary {
  background-color: var(--background-header-secondary);
}

.bg-background-modifier-hover {
  background-color: var(--background-modifier-hover);
}

.bg-background-modifier-selected {
  background-color: var(--background-modifier-selected);
}

.bg-background-navbar-surface {
  background-color: var(--background-navbar-surface);
}

.bg-background-primary {
  background-color: var(--background-primary);
}

.bg-background-secondary {
  background-color: var(--background-secondary);
}

.bg-background-secondary-alt {
  background-color: var(--background-secondary-alt);
}

.bg-background-tertiary {
  background-color: var(--background-tertiary);
}

.bg-background-textarea {
  background-color: var(--background-textarea);
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity, 1));
}

.bg-black\/40 {
  background-color: #0006;
}

.bg-black\/50 {
  background-color: #00000080;
}

.bg-black\/60 {
  background-color: #0009;
}

.bg-black\/75 {
  background-color: #000000bf;
}

.bg-blue-500\/10 {
  background-color: #3b82f61a;
}

.bg-brand-primary {
  background-color: var(--brand-primary);
}

.bg-brand-primary-fill {
  background-color: var(--brand-primary-fill);
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity, 1));
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity, 1));
}

.bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity, 1));
}

.bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1));
}

.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity, 1));
}

.bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity, 1));
}

.bg-status-danger {
  background-color: var(--status-danger);
}

.bg-text-chat-muted {
  background-color: var(--text-chat-muted);
}

.bg-text-primary {
  background-color: var(--text-primary);
}

.bg-text-primary-muted {
  background-color: var(--text-primary-muted);
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.bg-white\/10 {
  background-color: #ffffff1a;
}

.bg-yellow-500\/10 {
  background-color: #eab3081a;
}

.bg-opacity-50 {
  --tw-bg-opacity: .5;
}

.bg-gradient-to-br {
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
}

.from-indigo-950 {
  --tw-gradient-from: #1e1b4b var(--tw-gradient-from-position);
  --tw-gradient-to: #1e1b4b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-purple-900 {
  --tw-gradient-to: #581c87 var(--tw-gradient-to-position);
}

.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}

.fill-brand-primary-fill {
  fill: var(--brand-primary-fill);
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: .25rem;
}

.p-2 {
  padding: .5rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-\[11px\] {
  padding: 11px;
}

.p-\[16px\] {
  padding: 16px;
}

.p-px {
  padding: 1px;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-1\.5 {
  padding-left: .375rem;
  padding-right: .375rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-\[\.275rem\] {
  padding-left: .275rem;
  padding-right: .275rem;
}

.px-\[10px\] {
  padding-left: 10px;
  padding-right: 10px;
}

.px-\[16px\] {
  padding-left: 16px;
  padding-right: 16px;
}

.px-\[4px\] {
  padding-left: 4px;
  padding-right: 4px;
}

.px-\[6px\] {
  padding-left: 6px;
  padding-right: 6px;
}

.px-\[8px\] {
  padding-left: 8px;
  padding-right: 8px;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-\[\.125rem\] {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.py-\[10px\] {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-\[11px\] {
  padding-top: 11px;
  padding-bottom: 11px;
}

.py-\[2px\] {
  padding-top: 2px;
  padding-bottom: 2px;
}

.py-\[30px\] {
  padding-top: 30px;
  padding-bottom: 30px;
}

.py-\[6px\] {
  padding-top: 6px;
  padding-bottom: 6px;
}

.py-\[8px\] {
  padding-top: 8px;
  padding-bottom: 8px;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-px {
  padding-bottom: 1px;
}

.pl-0 {
  padding-left: 0;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-\[110px\] {
  padding-left: 110px;
}

.pl-\[16px\] {
  padding-left: 16px;
}

.pl-\[2px\] {
  padding-left: 2px;
}

.pl-\[72px\] {
  padding-left: 72px;
}

.pr-12 {
  padding-right: 3rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-\[11px\] {
  padding-right: 11px;
}

.pr-\[16px\] {
  padding-right: 16px;
}

.pr-\[18px\] {
  padding-right: 18px;
}

.pr-\[2px\] {
  padding-right: 2px;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-\[\.15em\] {
  padding-top: .15em;
}

.pt-\[16px\] {
  padding-top: 16px;
}

.pt-\[30px\] {
  padding-top: 30px;
}

.pt-\[3px\] {
  padding-top: 3px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.indent-0 {
  text-indent: 0;
}

.align-baseline {
  vertical-align: baseline;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.font-mono {
  font-family: var(--font-mono);
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-\[\.625rem\] {
  font-size: .625rem;
}

.text-\[\.6875rem\] {
  font-size: .6875rem;
}

.text-\[\.75rem\] {
  font-size: .75rem;
}

.text-\[10px\] {
  font-size: 10px;
}

.text-\[12px\] {
  font-size: 12px;
}

.text-\[14px\] {
  font-size: 14px;
}

.text-\[16px\] {
  font-size: 16px;
}

.text-\[8px\] {
  font-size: 8px;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.italic {
  font-style: italic;
}

.not-italic {
  font-style: normal;
}

.leading-4 {
  line-height: 1rem;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-\[\.9375rem\] {
  line-height: .9375rem;
}

.leading-\[0px\] {
  line-height: 0;
}

.leading-\[1\.125rem\] {
  line-height: 1.125rem;
}

.leading-\[1\.25\] {
  line-height: 1.25;
}

.leading-\[1\.2857\] {
  line-height: 1.2857;
}

.leading-\[1\.33\] {
  line-height: 1.33;
}

.leading-\[1\.375rem\] {
  line-height: 1.375rem;
}

.leading-\[13px\] {
  line-height: 13px;
}

.leading-\[16px\] {
  line-height: 16px;
}

.leading-\[18px\] {
  line-height: 18px;
}

.leading-\[20px\] {
  line-height: 20px;
}

.leading-\[24px\] {
  line-height: 24px;
}

.leading-\[inherit\] {
  line-height: inherit;
}

.leading-none {
  line-height: 1;
}

.leading-snug {
  line-height: 1.375;
}

.text-\[hsl\(39\,57\%\,64\%\)\] {
  --tw-text-opacity: 1;
  color: hsl(39 57% 64% / var(--tw-text-opacity, 1));
}

.text-background-header-primary {
  color: var(--background-header-primary);
}

.text-background-primary {
  color: var(--background-primary);
}

.text-blue-400 {
  --tw-text-opacity: 1;
  color: rgb(96 165 250 / var(--tw-text-opacity, 1));
}

.text-brand-primary {
  color: var(--brand-primary);
}

.text-brand-primary-fill {
  color: var(--brand-primary-fill);
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity, 1));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity, 1));
}

.text-status-danger {
  color: var(--status-danger);
}

.text-status-online {
  color: var(--status-online);
}

.text-text-chat {
  color: var(--text-chat);
}

.text-text-chat-muted {
  color: var(--text-chat-muted);
}

.text-text-link {
  color: var(--text-link);
}

.text-text-on-brand-primary {
  color: var(--text-on-brand-primary);
}

.text-text-primary {
  color: var(--text-primary);
}

.text-text-primary-muted {
  color: var(--text-primary-muted);
}

.text-text-tertiary {
  color: var(--text-tertiary);
}

.text-text-tertiary-muted {
  color: var(--text-tertiary-muted);
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.text-white\/90 {
  color: #ffffffe6;
}

.text-yellow-500 {
  --tw-text-opacity: 1;
  color: rgb(234 179 8 / var(--tw-text-opacity, 1));
}

.underline {
  text-decoration-line: underline;
}

.line-through {
  text-decoration-line: line-through;
}

.no-underline {
  text-decoration-line: none;
}

.caret-text-chat {
  caret-color: var(--text-chat);
}

.accent-brand-primary {
  accent-color: var(--brand-primary);
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-30 {
  opacity: .3;
}

.opacity-50 {
  opacity: .5;
}

.opacity-60 {
  opacity: .6;
}

.opacity-70 {
  opacity: .7;
}

.opacity-75 {
  opacity: .75;
}

.opacity-95 {
  opacity: .95;
}

.opacity-\[\.64\] {
  opacity: .64;
}

.shadow-\[0_0_0_2px_var\(--brand-primary\)\] {
  --tw-shadow: 0 0 0 2px var(--brand-primary);
  --tw-shadow-colored: 0 0 0 2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0_0_0_3px_var\(--background-secondary\)\] {
  --tw-shadow: 0 0 0 3px var(--background-secondary);
  --tw-shadow-colored: 0 0 0 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline {
  outline-style: solid;
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-100 {
  transition-duration: .1s;
}

.duration-150 {
  transition-duration: .15s;
}

.duration-200 {
  transition-duration: .2s;
}

.duration-300 {
  transition-duration: .3s;
}

.duration-500 {
  transition-duration: .5s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.contain-paint {
  --tw-contain-paint: paint;
  contain: var(--tw-contain-size) var(--tw-contain-layout) var(--tw-contain-paint) var(--tw-contain-style);
}

.\[overflow-anchor\:none\] {
  overflow-anchor: none;
}

@font-face {
  font-family: Lato;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("@fontsource/lato/files/lato-latin-400-normal.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Lato;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("@fontsource/lato/files/lato-latin-700-normal.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Lato;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("@fontsource/lato/files/lato-latin-ext-400-normal.woff2") format("woff2");
  unicode-range: U+100-2BA, U+2BD-2C5, U+2C7-2CC, U+2CE-2D7, U+2DD-2FF, U+304, U+308, U+329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Lato;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("@fontsource/lato/files/lato-latin-ext-700-normal.woff2") format("woff2");
  unicode-range: U+100-2BA, U+2BD-2C5, U+2C7-2CC, U+2CE-2D7, U+2DD-2FF, U+304, U+308, U+329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Lato;
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("@fontsource/lato/files/lato-latin-400-italic.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Lato;
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url("@fontsource/lato/files/lato-latin-700-italic.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Lato;
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("@fontsource/lato/files/lato-latin-ext-400-italic.woff2") format("woff2");
  unicode-range: U+100-2BA, U+2BD-2C5, U+2C7-2CC, U+2CE-2D7, U+2DD-2FF, U+304, U+308, U+329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Lato;
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url("@fontsource/lato/files/lato-latin-ext-700-italic.woff2") format("woff2");
  unicode-range: U+100-2BA, U+2BD-2C5, U+2C7-2CC, U+2CE-2D7, U+2DD-2FF, U+304, U+308, U+329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Monaspace Neon;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("@fontsource/monaspace-neon/files/monaspace-neon-latin-400-normal.woff2") format("woff2");
}

@font-face {
  font-family: Monaspace Neon;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("@fontsource/monaspace-neon/files/monaspace-neon-latin-700-normal.woff2") format("woff2");
}

@font-face {
  font-family: Monaspace Neon;
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("@fontsource/monaspace-neon/files/monaspace-neon-latin-400-italic.woff2") format("woff2");
}

@font-face {
  font-family: Monaspace Neon;
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url("@fontsource/monaspace-neon/files/monaspace-neon-latin-700-italic.woff2") format("woff2");
}

:root {
  --saturation-factor: 1;
  --user-select: none;
  --font-sans: "Lato", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --font-mono: "Monaspace Neon", ui-monospace, SFMono-Regular, SF Mono, Consolas, "Liberation Mono", Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono", "Roboto Mono", "Oxygen Mono", Ubuntu Monospace, "Source Code Pro", "Fira Mono", "Droid Sans Mono", "Courier New", monospace;
  --z-index-base: 0;
  --z-index-elevated-1: 10;
  --z-index-elevated-2: 20;
  --z-index-elevated-3: 30;
  --z-index-popout: 40;
  --z-index-modal: 50;
  --z-index-tooltip: 60;
  --z-index-overlay: 9999;
  --background-primary: hsl(0, calc(0% * var(--saturation-factor)), 6%);
  --background-secondary: hsl(0, calc(0% * var(--saturation-factor)), 9%);
  --background-secondary-alt: hsl(0, calc(0% * var(--saturation-factor)), 11%);
  --background-tertiary: hsl(0, calc(0% * var(--saturation-factor)), 13%);
  --background-textarea: hsl(0, calc(0% * var(--saturation-factor)), 18%);
  --background-header-primary: hsl(0, calc(0% * var(--saturation-factor)), 18%);
  --background-header-primary-hover: hsl(0, calc(0% * var(--saturation-factor)), 25%);
  --background-header-secondary: hsl(0, calc(0% * var(--saturation-factor)), 18%);
  --background-channel-header: hsl(0, calc(0% * var(--saturation-factor)), 15%);
  --background-chat-primary: hsl(0, calc(0% * var(--saturation-factor)), 9%);
  --background-navbar-surface: hsl(0, calc(0% * var(--saturation-factor)), 17%);
  --background-modifier-hover: hsla(0, calc(0% * var(--saturation-factor)), 100%, .05);
  --background-modifier-selected: hsla(0, calc(0% * var(--saturation-factor)), 100%, .1);
  --brand-primary: hsl(242, calc(67% * var(--saturation-factor)), 55%);
  --brand-secondary: hsl(242, calc(56% * var(--saturation-factor)), 49%);
  --brand-primary-light: hsl(242, calc(100% * var(--saturation-factor)), 84%);
  --brand-primary-fill: hsl(0, calc(0% * var(--saturation-factor)), 100%);
  --status-online: hsl(142, calc(76% * var(--saturation-factor)), 36%);
  --status-idle: hsl(45, calc(93% * var(--saturation-factor)), 47%);
  --status-dnd: hsl(0, calc(84% * var(--saturation-factor)), 60%);
  --status-offline: hsl(218, calc(11% * var(--saturation-factor)), 65%);
  --status-danger: hsl(1, calc(77% * var(--saturation-factor)), 55%);
  --text-primary: hsl(0, calc(0% * var(--saturation-factor)), 94%);
  --text-primary-muted: hsl(0, calc(0% * var(--saturation-factor)), 60%);
  --text-chat: hsl(0, calc(0% * var(--saturation-factor)), 86%);
  --text-chat-muted: hsl(0, calc(0% * var(--saturation-factor)), 63%);
  --text-link: hsl(218, calc(100% * var(--saturation-factor)), 69%);
  --text-tertiary: hsl(0, calc(0% * var(--saturation-factor)), 74%);
  --text-tertiary-muted: hsl(0, calc(0% * var(--saturation-factor)), 54%);
  --text-tertiary-secondary: hsl(0, calc(0% * var(--saturation-factor)), 48%);
  --text-on-brand-primary: hsl(0, calc(0% * var(--saturation-factor)), 94%);
  --markup-mention-text: hsl(236, calc(87% * var(--saturation-factor)), 91%);
  --markup-mention-fill: hsla(235, calc(86% * var(--saturation-factor)), 65%, .5);
  --markup-interactive-hover-text: hsl(0, calc(0% * var(--saturation-factor)), 100%);
  --markup-interactive-hover-fill: hsl(235, calc(86% * var(--saturation-factor)), 65%);
  --interactive-muted: color-mix(in oklab, hsl(228 calc(var(--saturation-factor) * 6%) 32.5% / 1) 100%, #a299ff 38%);
  --interactive-active: color-mix(in oklab, hsl(0 calc(var(--saturation-factor) * 0%) 100% / 1) 100%, #a299ff 38%);
  --button-primary-fill: hsl(139, calc(47% * var(--saturation-factor)), 44%);
  --button-primary-active-fill: hsl(136, calc(52% * var(--saturation-factor)), 38%);
  --button-primary-text: hsl(0, calc(0% * var(--saturation-factor)), 100%);
  --button-secondary-fill: hsla(0, calc(0% * var(--saturation-factor)), 100%, .3);
  --button-secondary-active-fill: hsla(0, calc(0% * var(--saturation-factor)), 100%, .2);
  --button-secondary-text: hsl(0, calc(0% * var(--saturation-factor)), 100%);
  --button-secondary-active-text: var(--button-secondary-text);
  --button-danger-fill: hsl(359, calc(67% * var(--saturation-factor)), 54%);
  --button-danger-active-fill: hsl(359, calc(56% * var(--saturation-factor)), 40%);
  --button-danger-text: hsl(0, calc(0% * var(--saturation-factor)), 100%);
  --button-danger-outline-border: 1px solid hsl(359, calc(67% * var(--saturation-factor)), 54%);
  --button-danger-outline-text: hsl(0, calc(0% * var(--saturation-factor)), 100%);
  --button-danger-outline-active-fill: hsl(359, calc(57% * var(--saturation-factor)), 48%);
  --button-danger-outline-active-border: transparent;
  --button-ghost-text: hsl(0, calc(0% * var(--saturation-factor)), 100%);
  --button-inverted-fill: hsl(0, calc(0% * var(--saturation-factor)), 100%);
  --button-inverted-text: hsl(0, calc(0% * var(--saturation-factor)), 0%);
  --theme-border: hsl(0, calc(0% * var(--saturation-factor)), 28%);
  --theme-border-width: 0px;
}

html {
  background-color: var(--background-primary);
  color: var(--text-primary);
  overscroll-behavior: none;
  user-select: var(--user-select);
  font-family: var(--font-sans);
  overflow: hidden;
}

body {
  scrollbar-color: var(--background-textarea) #0000;
}

.theme-light {
  --background-channel-header: hsl(0, calc(0% * var(--saturation-factor)), 91%);
  --background-chat-primary: hsl(0, calc(0% * var(--saturation-factor)), 96%);
  --background-header-primary: hsl(0, calc(0% * var(--saturation-factor)), 87%);
  --background-header-primary-hover: hsl(0, calc(0% * var(--saturation-factor)), 90%);
  --background-header-secondary: hsl(0, calc(0% * var(--saturation-factor)), 82%);
  --background-primary: hsl(0, calc(0% * var(--saturation-factor)), 98%);
  --background-secondary: hsl(0, calc(0% * var(--saturation-factor)), 89%);
  --background-secondary-alt: hsl(0, calc(0% * var(--saturation-factor)), 86%);
  --background-tertiary: hsl(0, calc(0% * var(--saturation-factor)), 86%);
  --background-textarea: hsl(0, calc(0% * var(--saturation-factor)), 87%);
  --background-navbar-surface: hsl(0, calc(0% * var(--saturation-factor)), 81%);
  --text-chat: hsl(0, calc(0% * var(--saturation-factor)), 29%);
  --text-primary: hsl(0, calc(0% * var(--saturation-factor)), 20%);
  --text-primary-muted: hsl(0, calc(0% * var(--saturation-factor)), 53%);
  --text-tertiary: hsl(0, calc(0% * var(--saturation-factor)), 47%);
  --text-tertiary-muted: hsl(0, calc(0% * var(--saturation-factor)), 73%);
  --text-tertiary-secondary: hsl(0, calc(0% * var(--saturation-factor)), 66%);
}

.theme-solarized {
  --background-channel-header: hsl(44, calc(100% * var(--saturation-factor)), 92%);
  --background-chat-primary: hsl(44, calc(86% * var(--saturation-factor)), 91%);
  --background-header-primary: hsl(44, calc(100% * var(--saturation-factor)), 92%);
  --background-header-primary-hover: hsl(53, calc(74% * var(--saturation-factor)), 91%);
  --background-header-secondary: hsl(53, calc(74% * var(--saturation-factor)), 91%);
  --background-modifier-hover: hsla(0, calc(0% * var(--saturation-factor)), 0%, .04);
  --background-modifier-selected: hsla(0, calc(0% * var(--saturation-factor)), 0%, .08);
  --background-primary: hsl(44, calc(67% * var(--saturation-factor)), 86%);
  --background-secondary: hsl(44, calc(100% * var(--saturation-factor)), 92%);
  --background-secondary-alt: hsl(44, calc(100% * var(--saturation-factor)), 92%);
  --background-tertiary: hsl(45, calc(80% * var(--saturation-factor)), 90%);
  --background-textarea: hsl(45, calc(89% * var(--saturation-factor)), 83%);
  --background-navbar-surface: hsl(44, calc(89% * var(--saturation-factor)), 83%);
  --text-chat: hsl(27, calc(70% * var(--saturation-factor)), 22%);
  --text-chat-muted: hsl(0, calc(0% * var(--saturation-factor)), 57%);
  --text-link: hsl(242, calc(67% * var(--saturation-factor)), 55%);
  --text-primary: hsl(27, calc(70% * var(--saturation-factor)), 22%);
  --text-primary-muted: hsl(27, calc(40% * var(--saturation-factor)), 41%);
  --text-tertiary: hsl(64, calc(70% * var(--saturation-factor)), 22%);
  --text-tertiary-muted: hsl(64, calc(39% * var(--saturation-factor)), 37%);
  --text-tertiary-secondary: hsl(64, calc(31% * var(--saturation-factor)), 56%);
  --theme-border: hsl(0, calc(0% * var(--saturation-factor)), 78%);
  --theme-border-width: 0px;
  --button-secondary-text: hsl(0, calc(0% * var(--saturation-factor)), 13%);
  --button-secondary-active-text: hsl(0, calc(0% * var(--saturation-factor)), 93%);
  --button-secondary-fill: hsla(0, calc(0% * var(--saturation-factor)), 0%, .33);
  --button-secondary-active-fill: hsla(0, calc(0% * var(--saturation-factor)), 0%, .8);
  --button-danger-outline-text: hsl(0, calc(0% * var(--saturation-factor)), 13%);
  --button-ghost-text: hsl(0, calc(0% * var(--saturation-factor)), 13%);
  --button-inverted-fill: hsl(0, calc(0% * var(--saturation-factor)), 13%);
}

.theme-midnight {
  --background-channel-header: hsl(0, calc(0% * var(--saturation-factor)), 0%);
  --background-chat-primary: hsl(0, calc(0% * var(--saturation-factor)), 0%);
  --background-header-primary: hsl(0, calc(0% * var(--saturation-factor)), 0%);
  --background-header-primary-hover: hsl(0, calc(0% * var(--saturation-factor)), 0%);
  --background-header-secondary: hsl(0, calc(0% * var(--saturation-factor)), 0%);
  --background-primary: hsl(0, calc(0% * var(--saturation-factor)), 0%);
  --background-secondary: hsl(0, calc(0% * var(--saturation-factor)), 0%);
  --background-secondary-alt: hsl(0, calc(0% * var(--saturation-factor)), 0%);
  --background-tertiary: hsl(0, calc(0% * var(--saturation-factor)), 0%);
  --background-textarea: hsl(0, calc(0% * var(--saturation-factor)), 0%);
  --text-chat: hsl(0, calc(0% * var(--saturation-factor)), 86%);
  --text-chat-muted: hsl(0, calc(0% * var(--saturation-factor)), 74%);
  --text-primary: hsl(0, calc(0% * var(--saturation-factor)), 86%);
  --text-primary-muted: hsl(0, calc(0% * var(--saturation-factor)), 78%);
  --text-tertiary: hsl(0, calc(0% * var(--saturation-factor)), 82%);
  --text-tertiary-muted: hsl(0, calc(0% * var(--saturation-factor)), 66%);
  --text-tertiary-secondary: hsl(0, calc(0% * var(--saturation-factor)), 63%);
  --theme-border: hsl(0, calc(0% * var(--saturation-factor)), 0%);
  --theme-border-width: 1px;
}

.theme-twilight {
  --background-channel-header: hsl(240, calc(11% * var(--saturation-factor)), 18%);
  --background-chat-primary: hsl(240, calc(14% * var(--saturation-factor)), 15%);
  --background-header-primary: hsl(240, calc(19% * var(--saturation-factor)), 17%);
  --background-header-primary-hover: hsl(240, calc(13% * var(--saturation-factor)), 20%);
  --background-header-secondary: hsl(240, calc(11% * var(--saturation-factor)), 18%);
  --background-primary: hsl(240, calc(24% * var(--saturation-factor)), 7%);
  --background-secondary: hsl(240, calc(22% * var(--saturation-factor)), 9%);
  --background-secondary-alt: hsl(240, calc(19% * var(--saturation-factor)), 11%);
  --background-tertiary: hsl(240, calc(16% * var(--saturation-factor)), 12%);
  --background-textarea: hsl(240, calc(11% * var(--saturation-factor)), 18%);
  --text-chat: hsl(0, calc(0% * var(--saturation-factor)), 88%);
  --text-chat-muted: hsl(0, calc(0% * var(--saturation-factor)), 64%);
  --text-primary: hsl(0, calc(0% * var(--saturation-factor)), 86%);
  --text-primary-muted: hsl(247, calc(18% * var(--saturation-factor)), 70%);
  --text-tertiary: hsl(240, calc(21% * var(--saturation-factor)), 77%);
  --text-tertiary-muted: hsl(240, calc(15% * var(--saturation-factor)), 64%);
  --text-tertiary-secondary: hsl(240, calc(10% * var(--saturation-factor)), 58%);
  --theme-border: hsl(240, calc(11% * var(--saturation-factor)), 18%);
}

.theme-sparkle {
  --font-sans: var(--font-mono);
  --background-channel-header: hsl(274, calc(74% * var(--saturation-factor)), 17%);
  --background-chat-primary: hsl(273, calc(100% * var(--saturation-factor)), 9%);
  --background-header-primary: hsl(274, calc(74% * var(--saturation-factor)), 17%);
  --background-header-primary-hover: hsla(274, calc(74% * var(--saturation-factor)), 17%, .67);
  --background-header-secondary: hsl(274, calc(74% * var(--saturation-factor)), 17%);
  --background-primary: hsl(273, calc(100% * var(--saturation-factor)), 9%);
  --background-secondary: hsl(274, calc(74% * var(--saturation-factor)), 17%);
  --background-secondary-alt: hsl(273, calc(100% * var(--saturation-factor)), 13%);
  --background-tertiary: hsl(273, calc(100% * var(--saturation-factor)), 13%);
  --background-textarea: hsl(321, calc(100% * var(--saturation-factor)), 21%);
  --background-navbar-surface: hsl(273, calc(100% * var(--saturation-factor)), 13%);
  --text-chat: hsl(331, calc(100% * var(--saturation-factor)), 53%);
  --text-chat-muted: hsla(331, calc(100% * var(--saturation-factor)), 53%, .67);
  --text-link: hsl(163, calc(100% * var(--saturation-factor)), 50%);
  --text-primary: hsl(331, calc(100% * var(--saturation-factor)), 53%);
  --text-primary-muted: hsla(331, calc(100% * var(--saturation-factor)), 53%, .67);
  --text-tertiary: hsl(266, calc(100% * var(--saturation-factor)), 80%);
  --text-tertiary-muted: hsla(266, calc(100% * var(--saturation-factor)), 80%, .67);
  --text-tertiary-secondary: hsl(266, calc(100% * var(--saturation-factor)), 80%);
}

:focus {
  outline: none;
}

input {
  --lightningcss-light: ;
  --lightningcss-dark: initial;
  color-scheme: dark;
}

.theme-light input, .theme-solarized input {
  --lightningcss-light: initial;
  --lightningcss-dark: ;
  color-scheme: light;
}

img[alt] {
  text-indent: -9999px;
}

code {
  font-family: var(--font-mono);
  font-size: 14px;
  line-height: 16px;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hljs-ansi-control-sequence {
  display: none;
}

.hljs-ansi-style-bold {
  font-weight: 700;
}

.hljs-ansi-style-underline {
  text-decoration: underline;
}

.hljs-ansi-foreground-black {
  color: var(--interactive-muted);
}

.hljs-ansi-foreground-black .hljs-ansi-background-black, .hljs-ansi-foreground-black .hljs-ansi-background-red, .hljs-ansi-foreground-black .hljs-ansi-background-green, .hljs-ansi-foreground-black .hljs-ansi-background-yellow, .hljs-ansi-foreground-black .hljs-ansi-background-blue, .hljs-ansi-foreground-black .hljs-ansi-background-magenta, .hljs-ansi-foreground-black .hljs-ansi-background-cyan, .hljs-ansi-foreground-black .hljs-ansi-background-white {
  color: #073642;
}

.hljs-ansi-foreground-red {
  color: #dc322f;
}

.hljs-ansi-foreground-green {
  color: #859900;
}

.hljs-ansi-foreground-yellow {
  color: #b58900;
}

.hljs-ansi-foreground-blue {
  color: #268bd2;
}

.hljs-ansi-foreground-magenta {
  color: #d33682;
}

.hljs-ansi-foreground-cyan {
  color: #2aa198;
}

.hljs-ansi-foreground-white {
  color: var(--interactive-active);
}

.hljs-ansi-foreground-white .hljs-ansi-background-black, .hljs-ansi-foreground-white .hljs-ansi-background-red, .hljs-ansi-foreground-white .hljs-ansi-background-green, .hljs-ansi-foreground-white .hljs-ansi-background-yellow, .hljs-ansi-foreground-white .hljs-ansi-background-blue, .hljs-ansi-foreground-white .hljs-ansi-background-magenta, .hljs-ansi-foreground-white .hljs-ansi-background-cyan, .hljs-ansi-foreground-white .hljs-ansi-background-white {
  color: #eee8d5;
}

.hljs-ansi-background-black {
  background-color: #002b36;
}

.hljs-ansi-background-red {
  background-color: #cb4b16;
}

.hljs-ansi-background-green {
  background-color: #586e75;
}

.hljs-ansi-background-yellow {
  background-color: #657b83;
}

.hljs-ansi-background-blue {
  background-color: #839496;
}

.hljs-ansi-background-magenta {
  background-color: #6c71c4;
}

.hljs-ansi-background-cyan {
  background-color: #93a1a1;
}

.hljs-ansi-background-white {
  background-color: #fdf6e3;
}

.first-letter\:uppercase:first-letter {
  text-transform: uppercase;
}

.before\:pointer-events-none:before {
  content: var(--tw-content);
  pointer-events: none;
}

.before\:absolute:before {
  content: var(--tw-content);
  position: absolute;
}

.before\:bottom-0:before {
  content: var(--tw-content);
  bottom: 0;
}

.before\:left-0:before {
  content: var(--tw-content);
  left: 0;
}

.before\:top-0:before {
  content: var(--tw-content);
  top: 0;
}

.before\:block:before {
  content: var(--tw-content);
  display: block;
}

.before\:w-\[2px\]:before {
  content: var(--tw-content);
  width: 2px;
}

.before\:bg-blue-500:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity, 1));
}

.before\:bg-yellow-500:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(234 179 8 / var(--tw-bg-opacity, 1));
}

.first\:mt-0:first-child {
  margin-top: 0;
}

.hover\:scale-105:hover {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:scale-110:hover {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:bg-background-header-primary-hover:hover {
  background-color: var(--background-header-primary-hover);
}

.hover\:bg-background-modifier-hover:hover {
  background-color: var(--background-modifier-hover);
}

.hover\:bg-background-secondary:hover {
  background-color: var(--background-secondary);
}

.hover\:bg-background-tertiary:hover {
  background-color: var(--background-tertiary);
}

.hover\:bg-blue-500\/10:hover {
  background-color: #3b82f61a;
}

.hover\:bg-brand-primary:hover {
  background-color: var(--brand-primary);
}

.hover\:bg-brand-secondary:hover {
  background-color: var(--brand-secondary);
}

.hover\:bg-gray-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity, 1));
}

.hover\:bg-gray-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity, 1));
}

.hover\:bg-gray-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1));
}

.hover\:bg-green-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(21 128 61 / var(--tw-bg-opacity, 1));
}

.hover\:bg-status-danger:hover {
  background-color: var(--status-danger);
}

.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.hover\:bg-white\/10:hover {
  background-color: #ffffff1a;
}

.hover\:bg-white\/20:hover {
  background-color: #fff3;
}

.hover\:bg-yellow-500\/10:hover {
  background-color: #eab3081a;
}

.hover\:text-brand-primary-fill:hover {
  color: var(--brand-primary-fill);
}

.hover\:text-green-600:hover {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity, 1));
}

.hover\:text-text-chat:hover {
  color: var(--text-chat);
}

.hover\:text-text-primary:hover {
  color: var(--text-primary);
}

.hover\:text-text-tertiary:hover {
  color: var(--text-tertiary);
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.hover\:text-yellow-500:hover {
  --tw-text-opacity: 1;
  color: rgb(234 179 8 / var(--tw-text-opacity, 1));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:opacity-100:hover {
  opacity: 1;
}

.hover\:shadow-lg:hover {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-black\/10:hover {
  --tw-shadow-color: #0000001a;
  --tw-shadow: var(--tw-shadow-colored);
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-inset:focus {
  --tw-ring-inset: inset;
}

.focus\:ring-brand-primary:focus {
  --tw-ring-color: var(--brand-primary);
}

.active\:translate-y-px:active {
  --tw-translate-y: 1px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.active\:scale-95:active {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.active\:cursor-grabbing:active {
  cursor: grabbing;
}

.active\:bg-green-800:active {
  --tw-bg-opacity: 1;
  background-color: rgb(22 101 52 / var(--tw-bg-opacity, 1));
}

.group:hover .group-hover\:translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group\/external:hover .group-hover\/external\:scale-105, .group\/play:hover .group-hover\/play\:scale-105 {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:scale-\[1\.02\] {
  --tw-scale-x: 1.02;
  --tw-scale-y: 1.02;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:opacity-50 {
  opacity: .5;
}

.group\/external:active .group-active\/external\:scale-95, .group\/play:active .group-active\/play\:scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:not(:hover):not([media-paused]) .group-\[\&\:not\(\:hover\)\:not\(\[media-paused\]\)\]\:translate-y-full {
  --tw-translate-y: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:checked ~ .peer-checked\:bg-brand-primary {
  background-color: var(--brand-primary);
}

.aria-expanded\:bg-background-header-primary-hover[aria-expanded="true"] {
  background-color: var(--background-header-primary-hover);
}

.aria-expanded\:bg-background-modifier-selected[aria-expanded="true"] {
  background-color: var(--background-modifier-selected);
}

.aria-expanded\:text-text-primary[aria-expanded="true"] {
  color: var(--text-primary);
}

.aria-expanded\:opacity-100[aria-expanded="true"] {
  opacity: 1;
}

.aria-selected\:bg-background-modifier-selected[aria-selected="true"] {
  background-color: var(--background-modifier-selected);
}

.aria-selected\:text-text-primary[aria-selected="true"] {
  color: var(--text-primary);
}

@media (prefers-reduced-motion: reduce) {
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  .motion-reduce\:animate-\[spin_1\.5s_linear_infinite\] {
    animation: 1.5s linear infinite spin;
  }
}

@media (min-width: 841px) {
  .md\:hidden {
    display: none;
  }

  .md\:w-60, .md\:w-\[15rem\] {
    width: 15rem;
  }

  .md\:max-w-\[15rem\] {
    max-width: 15rem;
  }

  .md\:grid-cols-\[15rem\,1fr\] {
    grid-template-columns: 15rem 1fr;
  }

  .md\:grid-rows-\[3\.5rem\,1fr\,3\.5rem\] {
    grid-template-rows: 3.5rem 1fr 3.5rem;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:bg-background-primary {
    background-color: var(--background-primary);
  }

  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

@media (min-width: 1024px) {
  .lg\:w-\[60px\] {
    width: 60px;
  }
}
